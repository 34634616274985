import moment from 'moment';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaTimesCircle } from 'react-icons/fa';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 99;
  height: 100%;
  width: 100%;
  display: ${props => (props.isOpen ? 'initial' : 'none')};
  cursor: pointer;
`;

const ModalWraper = styled.div`
  min-width: 700px;
  max-width: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 400px;
  max-height: 85%;
  background: white;
  position: fixed;
  z-index: 100;
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  padding: 30px;
  box-sizing: border-box;
  border-radius: 15px;
  align-items: flex-end;
  flex-direction: column;
  border: 7px goldenrod solid;
  @media (max-width: 900px) {
    min-width: 90%;
    max-width: 100%;
  }
`;

const CloseBtnWraper = styled.div`
  max-height: 40px;
  margin-bottom: 30px;
`;

const CloseIcon = styled(FaTimesCircle)`
  cursor: pointer;
  color: rgba(227, 27, 50, 0.78);
  transition: color 0.45s;
  &:hover {
    color: rgba(227, 27, 50, 1);
  }
`;

export default function Modal({ children, modalId = '0' }) {
  const [isOpen, setIsOpen] = useState(false);
  const modalSessionString = 'modalSession' + modalId;

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    setMemory();
  };

  const setMemory = () => {
    localStorage.setItem(modalSessionString, moment());
  };

  const getMemory = () => {
    return localStorage.getItem(modalSessionString);
  };

  const clearMemory = () => {
    localStorage.removeItem(modalSessionString);
  };

  useEffect(() => {
    const lastSession = getMemory();
    if (lastSession) {
      setIsOpen(false);
      const week = moment(lastSession).add(1, 'days');
      if (week < moment()) {
        clearMemory();
        setTimeout(() => {
          setIsOpen(true);
        }, 3000);
      }
    } else {
      setTimeout(() => {
        setIsOpen(true);
      }, 3000);
    }
  }, []);

  return (
    <>
      <Container onClick={toggleOpen} isOpen={isOpen} />
      <ModalWraper isOpen={isOpen}>
        <CloseBtnWraper onClick={toggleOpen}>
          <CloseIcon size={40} />
        </CloseBtnWraper>
        {children}
      </ModalWraper>
    </>
  );
}
