import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Container = styled.div`
  background: rgb(0, 0, 0, 0.08);
  position: fixed;
  top: 190px;
  right: 0;
  width: 18%;
  @media (max-width: 1280px) {
    top: 70px;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

const CardContainer = styled.div`
  width: 90%;
  margin: 10px auto;
  height: 180px;
  background: red;
  @media (max-width: 1280px) {
    height: 250px;
  }
`;

const RecommendedCardWrapper = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: 65% 35%;
  width: 100%;
  background: white;
  transition: transform 0.4s, box-shadow 0.4s;
  &:hover {
    transform: scale(1.02);
    box-shadow: 2px 7px 4px -3px rgba(0, 0, 0, 0.3);
  }
  @media (max-width: 1380px) {
    grid-template-rows: 55% 45%;
  }
`;

const CardImg = styled.div`
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
`;

const CardText = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: black;
  padding: 5px;
  @media (max-width: 1280px) {
    font-size: 15px;
  }
`;

const Card = ({ post }) => (
  <Link
    to={`/blog/${post.slug}`}
    style={{
      textDecoration: 'none',
    }}
  >
    <CardContainer>
      <RecommendedCardWrapper>
        <CardImg src={post.postThumbnail.file.url} />
        <CardText>{post.postTitle}</CardText>
      </RecommendedCardWrapper>
    </CardContainer>
  </Link>
);

const FloatingSideline = ({ data, type }) => (
  <Container>
    <h2 style={{ textAlign: 'center', fontWeight: '400', fontSize: '20px' }}>
      Related Posts:
    </h2>
    {type === 'post' &&
      data.map((post, i) => <Card post={post.node} key={'FS' + i} />)}
  </Container>
);

export default FloatingSideline;
