import React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import get from 'lodash/get';
import filter from 'lodash/filter';
import styled from 'styled-components';
import { getRandom } from '../../utils/array';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import FloatingSideline from '../../components/FloatingSideline';
import SideShareButtons from '../../components/SideShareButtons';

import { Modal } from '../../components/Modal';

const P = styled.p`
  font-size: 14px;
  padding: 10px 0;
  line-height: 26px;
  @media (max-width: 900px) {
    font-size: 16px;
  }
`;

const IMG = styled.img`
  height: auto;
  max-height: 1000px;
  max-width: 75%;
  margin: 40px auto;
  display: block;
  @media (max-width: 900px) {
    max-width: 95%;
  }
`;

const Title = styled.h1`
  text-align: center;
  margin: 0px 0px 50px;
  padding-top: 40px;
  font-size: 32px;
`;

const Image = ({ node }) => {
  const src = node.data.target.fields.file['en-US'].url;
  let alt = '';
  if (node.data.target.fields.description['en-US']) {
    alt = node.data.target.fields.description['en-US'];
  }
  return <IMG src={src} className="img-fluid" alt={alt} />;
};

const Video = ({ url }) => {
  return (
    <div
      className="video"
      style={{
        position: 'relative',
        paddingBottom: '56.25%' /* 16:9 */,
        paddingTop: 25,
        height: 0,
      }}
    >
      <iframe
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        src={url}
        frameBorder="0"
      />
    </div>
  );
};

const RecommendedPostsWrapper = styled.div`
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #f5bb0c;
  justify-content: space-around;
  /* flex-wrap: wrap; */
  @media (max-width: 1550px) {
    flex-wrap: wrap;
  }
  @media (max-width: 1420px) {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    justify-items: center;
  }
  @media (max-width: 745px) {
    grid-template-columns: 100%;
  }
`;

const RecommendedPosts = ({ posts }) => {
  return (
    <>
      <div style={{ marginTop: '35px', background: 'white' }}>
        <h2 style={{ textAlign: 'center' }}>
          Become <span style={{ color: '#f5bb0c' }}>Suave</span>!
        </h2>
        <p style={{ textAlign: 'center', color: 'rgba(0,0,0,0.7)' }}>
          Read another post:
        </p>
      </div>
      <RecommendedPostsWrapper>
        {posts.map((post, i) => (
          <RecommendedCard key={'recCard' + i} post={post.node} />
        ))}
      </RecommendedPostsWrapper>
    </>
  );
};

const RecommendedCardWrapper = styled.div`
  display: grid;
  grid-template-rows: 150px 70px;
  width: 250px;
  margin: 10px;
  background: white;
  padding: 5px;
  grid-gap: 15px;
  transition: transform 0.4s, box-shadow 0.4s;
  &:hover {
    transform: scale(1.05);
    box-shadow: 2px 12px 4px -3px rgba(0, 0, 0, 0.3);
  }
  @media (max-width: 1900px) {
    width: 200px;
  }
  @media (max-width: 1550px) {
    width: 180px;
  }
  @media (max-width: 1420px) {
    width: 300px;
  }
  @media (max-width: 1110px) {
    width: 250px;
  }
  @media (max-width: 900px) {
    width: 320px;
  }
  @media (max-width: 745px) {
    width: 80vw;
  }
`;

const CardImg = styled.div`
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
`;

const CardText = styled.p`
  font-size: 15px;
  font-weight: 700;
  color: black;
`;

const RecommendedCard = ({ post }) => (
  <Link
    to={`/blog/${post.slug}`}
    style={{
      textDecoration: 'none',
    }}
  >
    <RecommendedCardWrapper>
      <CardImg src={post.postThumbnail.file.url} />
      <CardText>{post.postTitle}</CardText>
    </RecommendedCardWrapper>
  </Link>
);

const handleEmbededEntry = (node, children) => {
  const type = node.data.target.fields.type['en-US'];
  if (type === 'video') {
    return <Video url={node.data.target.fields.url['en-US']} />;
  }
};

const Anchor = styled.a`
  color: #ce9d0a;
  transition: color 0.3s ease-in-out;
  &:hover {
    color: #ffc721;
  }
`;

const BTN = styled.button`
  background-color: rgba(255, 205, 56, 1);
  border: none;
  border-radius: 15px;
  padding: 25px 45px;
  color: black;
  font-size: 1.4em;
  font-weight: 700;
  cursor: pointer;
  align-self: center;
  margin-top: 50px;
  @media (max-width: 900px) {
    font-size: 1.1em;
  }
`;

class PostTemplate extends React.Component {
  render() {
    const allPosts = get(this.props, 'data.allContentfulBlog.edges');
    const post = get(this.props, 'data.contentfulBlog');
    const siteTitle = get(this.props, 'data.site.siteMetadata.title');
    const richTextDocument = post.postContent.json;
    const richTextIntro = JSON.parse(post.intro.intro);
    const featuredImage = post.featuredImage[0];
    const keywords = post.keywords.join(', ');
    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node, children) => <Image node={node} />,
        [BLOCKS.PARAGRAPH]: (node, children) => <P>{children}</P>,
        [BLOCKS.HEADING_1]: (node, children) => <h1>{children}</h1>,
        [BLOCKS.HEADING_2]: (node, children) => (
          <h2 style={{ margin: '50px auto' }}>{children}</h2>
        ),
        [BLOCKS.HEADING_3]: (node, children) => (
          <h3 style={{ margin: '50px auto' }}>{children}</h3>
        ),
        [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
        [BLOCKS.EMBEDDED_ENTRY]: (node, children) =>
          handleEmbededEntry(node, children),
        [INLINES.HYPERLINK]: (node, children) => {
          return (
            <Anchor href={node.data.uri} target="_blank">
              <b>{children}</b>
            </Anchor>
          );
        },
      },
    };

    const getRecommendedPosts = (n = 4) => {
      if (post.category[0] === 'null' || !post.category[0]) {
        return getRandom(allPosts, 4);
      }
      const sameCategoryPosts = filter(allPosts, o => {
        return (
          o.node.category[0] === post.category[0] && o.node.slug !== post.slug
        );
      });
      return getRandom(sameCategoryPosts, n);
    };

    console.log(post.category[0]);

    return (
      <Layout>
        <SEO
          title={post.postTitle}
          description={post.seoDescription}
          keyword={keywords}
        />
        {post.category[0] == 'charisma' && (
          <Modal>
            <h1
              style={{
                alignSelf: 'center',
                fontSize: '24px',
                textAlign: 'center',
              }}
            >
              Are you charismatic?
            </h1>
            <Link
              to="/quiz"
              style={{
                textDecoration: `none`,
                color: 'black',
                alignSelf: 'center',
              }}
            >
              <BTN>TAKE THE QUIZ!</BTN>
            </Link>
          </Modal>
        )}
        <FloatingSideline data={getRecommendedPosts(3)} type="post" />
        <SideShareButtons slug={`/blog/${post.slug}`}></SideShareButtons>
        <Title>{post.postTitle}</Title>
        {documentToReactComponents(richTextIntro, options)}
        <IMG
          src={featuredImage.file.url}
          alt={featuredImage.description && featuredImage.description}
        />
        {documentToReactComponents(richTextDocument, options)}
        <RecommendedPosts posts={getRecommendedPosts()} />
      </Layout>
    );
  }
}

export default PostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlog(slug: { eq: $slug }) {
      id
      slug
      postTitle
      seoDescription
      keywords
      category
      intro {
        intro
      }
      postContent {
        json
        nodeType
      }
      featuredImage {
        description
        file {
          url
          fileName
          contentType
        }
      }
    }
    allContentfulBlog {
      edges {
        node {
          postTitle
          category
          postThumbnail {
            file {
              url
              fileName
            }
          }
          slug
        }
      }
    }
  }
`;
